$fontSize: (
	8: 8px,
	10: 10px,
	12: 0.75rem,
	14: 14px,
	16: 1rem,
	18: 18px,
	24: 1.5rem,
	20: 1.25rem,
	32: 2rem,
	64: 4rem
);

$lineHeight: (
	16: 1rem,
	20: 1.25rem,
	24: 1.5rem,
	32: 2rem,
);

$textAlignment: (
	left,
	right,
	center,
	justify,
	initial,
	inherit
);

$textTransform: (
	capitalize,
	uppercase,
	lowercase,
	none
);

@each $key, $value in $fontSize {
	.fs-#{$key} {
		font-size: #{$value} !important;
	}
}


@each $key, $value in $lineHeight {
	.lh-#{$key} {
		line-height: #{$value};
	}
}

$fontWeights: (
	400,
	500,
	600,
	700,
	800,
	900
);

@each $key in $fontWeights {
	.fw-#{$key} {
		font-weight: #{$key};
	}
}

@each $value in $textAlignment {
	.txt-align-#{$value} {
		text-align: #{$value} !important;
	}
}

@each $key in $textTransform {
	.txt-transform-#{$key} {
		text-transform: #{$key};
	}
}

.bold {
	font-weight: bold;
}

.line-through {
	text-decoration: line-through;
}

.no-decoration {
	text-decoration: none;
}

.text-center {
	text-align: center;
}

.capitalize {
	text-transform: capitalize;
}

.manrope-font {
	font-family: 'Manrope';
}

// !!! Need to map these to OLD THEME
// TEXT-COLORS
$text-color-default: #282624;
$text-color-subtle: #7c7972;
$text-color-muted: #35312d;
$text-color-disabled: #b5b1ad;
$text-color-error: #e53811;

// FONT-SIZE
$font-size-tiny-caution: 0.625rem;
$font-size-small: 0.75rem;
$font-size-default: 0.875rem;
$font-size-large: 1rem;
$font-size-xl: 1.25rem;
$font-size-xxl: 1.5rem;
$font-size-xxxl-caution: 2rem;

// FONT-WEIGHT
$font-medium: 500;
$font-semi-bold: 600;
$font-bold: 700;

.subtle {
	color: $text-color-subtle !important;
}

.text {
	margin: 0;
	color: var(--neutral-8);
	font-size: 0.875rem;
	line-height: 20px;
	font-family: 'Manrope';

	// Font Weight
	&.regular {
		font-weight: 400;
	}

	&.medium {
		font-weight: $font-medium;
	}

	&.semi-bold {
		font-weight: $font-semi-bold;
	}

	&.bold {
		font-weight: $font-bold;
	}
}

.sentence-case {
	text-transform: lowercase;
}

.sentence-case::first-letter {
	text-transform: uppercase;
}