.custom-style {
    font-size: 50px;
}

.landing-cols {
    .landing-table {
        max-width: 100%;
        overflow: hidden;            
    }

    .landing-chart {
        max-width: 100%;
        overflow: hidden;
    }
}

@media only screen and (min-width: 1024px) {
    .landing-cols {
        .landing-table {
            flex: 1 1 45%;
        }

        .landing-chart {
            flex: 1 1 45%;
        }
    }
}