.elevation-0 {
  box-shadow: none;
}

.elevation-1 {
  box-shadow: 0px 1px 1px rgba(38, 38, 38, 0.25),
    0px 0px 1px rgba(38, 38, 38, 0.31);
}

.elevation-2 {
  box-shadow: 0px 3px 5px #091e424f, 0px 0px 1px #091e424f;
}

.elevation-3 {
  box-shadow: 0px 8px 12px rgba(38, 38, 38, 0.15),
    0px 0px 1px rgba(38, 38, 38, 0.31);
}

.elevation-4 {
  box-shadow: 0px 10px 18px rgba(38, 38, 38, 0.15),
    0px 0px 1px rgba(38, 38, 38, 0.31);
}

.elevation-5 {
  box-shadow: 0px 18px 28px rgba(38, 38, 38, 0.15),
    0px 0px 1px rgba(38, 38, 38, 0.31);
}