.header-outer {
    background-color: rgba(0, 0, 0, 0.25);
    position: fixed;
    width: 100%;
    z-index: 10;
    color: white;
    transition: background-color 500ms;
    font-weight: 800;

    .website-logo {
        height: 60px;
        filter: brightness(0) invert(1);
    }


    &:not([data-scroll='0']) {
        background-color: white;
        color: black;
        .website-logo {
            filter: none;
        }
        border-bottom: 1px solid #9e9e9e;
        border-top: 1px solid #9e9e9e;

        .header-nav-icon {
            color: rgba(0, 0, 0, 0.8) !important;
        }

        .header-nav-option {
            color: rgba(0, 0, 0, 0.8) !important;
        }
    }   
}

.white-logo {
    filter: brightness(0) invert(1);
}