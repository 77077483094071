$gridMetrics: (
  auto: auto,
  1: 8.3333333%,
  2: 16.6666666667%,
  3: 25%,
  4: 33.3333333333%,
  5: 41.6666666667%,
  6: 50%,
  7: 58.3333333333%,
  8: 66.66666666667%,
  9: 75%,
  10: 83.3333333333%,
  11: 91.6666666666%,
  12: 100%,
);

$bootstrap: (
  xs: 576,
  md: 768,
  lg: 992,
  xl: 1200,  
);

$bootstrap-config: (
  display: (
    hidden: none,
    block: block,    
  )  
);



@each $key, $value in $gridMetrics {
  .col-#{$key} {
    flex: 0 0 auto;
    width: #{$value};
  } 
}

@each $key, $value in $bootstrap {
  @media only screen and (max-width: #{$value}px) {
    @each $baseClass, $variants in $bootstrap-config {
      @each $variantName, $value in $variants {
        .#{$baseClass}-#{$key}-#{$variantName}-down {
          #{$baseClass}: #{$value} !important;
        }
      }
    }    
  } 

  @media only screen and (min-width: #{$value}px) {
    @each $baseClass, $variants in $bootstrap-config {
      @each $variantName, $value in $variants {
        .#{$baseClass}-#{$key}-#{$variantName}-up {
          #{$baseClass}: #{$value} !important;
        }
      }
    }    
  }  
}


.col-md-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}
