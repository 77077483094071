@import "./variables.scss";
@import './foundation/colors.scss';
@import './foundation/spacings.scss';
@import './foundation/flex.scss';
@import './foundation/grid.scss';
@import './foundation/typography.scss';
@import './foundation/elevations.scss';
@import './foundation/borders.scss';
@import './foundation/position.scss';
@import './foundation/overflow.scss';
@import 'leaflet/dist/leaflet.css';


body {
	margin: 0;
	font-family: 'Manrope', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

button.submit {
	border-color: $color-blue;
	background: $color-blue;
	color: $color-white;
}

button.archive {
	border-color: $color-white;
	background: $color-white;
	color: $color-red;
	box-shadow: none;
}

button.link-blue {
	border-color: $color-white;
	box-shadow: none;
	color: $color-blue;
}

input.ant-input {
	top: unset;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.1;
}

.ant-modal-body {
	padding: 0;
}

.overflow {
	overflow: auto;
}

.overflow-y {
	overflow-y: auto;
}

.overflow-x {
	overflow-x: auto;
}

.handle-overflow {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.cursor-default {
	cursor: default !important;
}

.cursor-disabled {
	cursor: not-allowed !important;
}

.opacity-4 {
	opacity: 0.4 !important;
}

.cursor-grab {
	cursor: grab !important;
}

.cursor-text {
	cursor: text !important;
}

.pointer,
.cursor-pointer {
	cursor: pointer !important;
}

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.list-inline {
	padding-left: 0;
	list-style: none;
}

.list-inline-item {
	display: inline-block;
}

.list-inline-item:not(:last-child) {
	margin-right: 0.5rem;
}

// TODO: Do we need it

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
	width: 100%;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}

.custom-scrollbar-css {
	scrollbar-gutter: stable;

	&:active::-webkit-scrollbar-thumb,
	&:focus::-webkit-scrollbar-thumb,
	&:hover::-webkit-scrollbar-thumb {
		visibility: visible;
		background: #a9a9a9;
	}

	&::-webkit-scrollbar-thumb {
		background-color: lightgray;
		visibility: hidden;
		border-radius: 15px;
	}

	&::-webkit-scrollbar {
		background: transparent;
		width: 5px;
		border-radius: 10px;
		z-index: 10;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
		border: none;
		box-shadow: none;
		-webkit-box-shadow: none;
	}
}

.scrollbar-css {
	::-webkit-scrollbar {
		width: 5px;
		background-color: #fff;
		border-radius: 10px;
		z-index: 10;
	}

	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		border-radius: 15px;
		background-color: #fff;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 15px;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: auto;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: darkgray;
		border-radius: 15px;
	}
}

.hover-underline {
	&:hover {
		text-decoration: underline;
	}
}

.hover-color-blue {
	&:hover {
		color: #1676c5;
	}
}

.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) / -2);
	margin-left: calc(var(--bs-gutter-x) / -2);
}

.row>* {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) / 2);
	padding-left: calc(var(--bs-gutter-x) / 2);
	margin-top: var(--bs-gutter-y);
}

.col {
	flex: 1 0 0%;
}

.w-100 {
	width: 100% !important;
}

.mw-100 {
	max-width: 100% !important;
}

.w-80 {
	width: 80% !important;
}

.mw-80 {
	max-width: 80% !important;
}

.mw-90 {
	max-width: 90% !important;
}

.w-70 {
	width: 70% !important;
}

.w-65 {
	width: 65% !important;
}

.w-35 {
	width: 35% !important;
}

.w-60 {
	width: 60% !important;
}

.w-50 {
	width: 50% !important;
}

.w-40 {
	width: 40% !important;
}

.w-30 {
	width: 30% !important;
}

.w-48 {
	width: 48% !important;
}

.w-49 {
	width: 49% !important;
}

.w-90 {
	width: 90% !important;
}

.w-95 {
	width: 95% !important;
}

.popover-p-0 .ant-popover-inner-content {
	padding: 0 !important;
}

.popover-no-arrow {
	.ant-popover-arrow {
		display: none;
	}
}

.popover-custom {
	&.new-popover-custom {
		.ant-popover-inner-content {
			width: 328px;

			.popup-item {
				width: 328px;

				&.custom-popup-item {
					box-shadow: none;
					border-radius: 0;

					>div {
						border-radius: 0;
					}
				}
			}
		}
	}

	.ant-popover-inner-content {
		padding: 0 !important;
		max-height: 400px;
		width: 393px;
		overflow: scroll;
		border-radius: 4px;
		box-shadow: 0px 0px 1px 0px rgba(38, 38, 38, 0.31),
			0px 18px 28px 0px rgba(38, 38, 38, 0.15);

		.popup-item {
			&.custom-popup-item {
				width: 328px;
				border-radius: 8px;
				box-shadow: 0px 0px 6px -2px rgba(16, 24, 40, 0.03),
					0px 0px 16px -4px rgba(16, 24, 40, 0.08);
			}

			border-bottom: 0.5px solid lightgrey;
		}
	}

	.ant-popover-arrow {
		display: none !important;
	}
}

.w-fit-content {
	width: fit-content !important;
}

.w-max-content {
	width: max-content;
}

.w-auto {
	width: auto;
}

.h-100 {
	height: 100%;
}

.h-90 {
	height: 90%;
}

.h-70 {
	height: 70%;
}

.h-90 {
	height: 90%;
}

.h-auto {
	height: auto;
}

.h-unset {
	height: unset;
}

.mh-100 {
	max-height: 100%;
}

.display-none,
.hidden {
	display: none;
}

.h-max-content {
	height: max-content;
}

.h-fit-content {
	height: fit-content;
}

.disabled {
	cursor: default;
	pointer-events: none;
	user-select: none;
}

.pointer-events-none {
	pointer-events: none;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

.white-space-nowrap {
	white-space: nowrap;
}

.border-solid {
	border: 1px solid;
}

.border-dashed {
	border: 1px dashed;
}

.flex-0 {
	flex: 0 0 auto;
}

.break-word {
	word-break: break-word;
}

.break-all {
	word-break: break-all;
}

.z-index-100 {
	z-index: 100;
}

.text-underline {
	text-decoration: underline;
}

.ant-custom-option.ant-select-dropdown-menu-item-selected,
.ant-custom-option.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
	background: #eff5fb;

	.ant-select-selected-icon {
		color: #6099d9;
	}
}

.ih-option.ant-select-dropdown-menu-item-selected,
.ih-option.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ih-option.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
	background: #f0f0f0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	.ant-select-selected-icon {
		color: #6099d9;
	}
}

.text-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.user-select-none {
	user-select: none;
}

// iframe {
//   display: none;
// }

.scrollbar-on-hover {
	scroll-behavior: smooth;
	overflow-y: overlay;

	&:active::-webkit-scrollbar-thumb,
	&:focus::-webkit-scrollbar-thumb,
	&:hover::-webkit-scrollbar-thumb {
		visibility: visible;
	}

	&::-webkit-scrollbar-thumb {
		background-color: lightgray;
		visibility: hidden;
	}

	&::-webkit-scrollbar {
		background: transparent !important;
	}

	&::-webkit-scrollbar-track {
		background: transparent !important;
		border: none;
		box-shadow: none;
		-webkit-box-shadow: none;
	}
}

.custom-clipboard-tooltip.ant-tooltip {
	.ant-tooltip-arrow {
		display: none;
	}

	.ant-tooltip-inner {
		padding: 8px 12px;
		color: #282624;
		text-align: left;
		font-weight: 600;
		font-size: 12px;
		text-decoration: none;
		word-wrap: break-word;
		background-color: white;
		border-radius: 8px;
		border: 1px solid #f2f4f7;
	}
}

.custom-clipboard-tooltip-dark-theme.ant-tooltip {
	.ant-tooltip-arrow {
		display: none;
	}

	.ant-tooltip-inner {
		padding: 8px 12px;
		text-align: left;
		font-weight: 600;
		font-size: 12px;
		color: #fff;
		text-decoration: none;
		word-wrap: break-word;
		border-radius: 8px;
		box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
			0px 12px 16px -4px rgba(16, 24, 40, 0.08);
		background-color: #35312d;
	}
}

.centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

.h-100v {
	height: 100vh;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	appearance: textfield;
}