$spacings: (
	0: 0px,
	2: 0.125rem,
	4: 0.25rem,
	6: 0.375rem,
	8: 0.5rem,
	12: 0.75rem,
	16: 1rem,
	20: 1.25rem,
	24: 1.5rem,
	32: 2rem,
	36: 2.25rem,
	40: 2.5rem,
	48: 3rem,
	56: 3.5rem,
	60: 3.75rem,
	64: 4rem,
	80: 5rem,
	92: 5.75rem,
	96: 6rem,
	auto: auto,
	unset: unset,
);

@each $key, $value in $spacings {
	.m-#{$key} {
		margin: #{$value} !important;
	}

	.mx-#{$key} {
		margin-right: #{$value} !important;
		margin-left: #{$value} !important;
	}

	.my-#{$key} {
		margin-top: #{$value} !important;
		margin-bottom: #{$value} !important;
	}

	.mt-#{$key} {
		margin-top: #{$value} !important;
	}

	.me-#{$key} {
		margin-right: #{$value} !important;
	}

	.mb-#{$key} {
		margin-bottom: #{$value} !important;
	}

	.ms-#{$key} {
		margin-left: #{$value} !important;
	}

	.p-#{$key} {
		padding: #{$value} !important;
	}

	.px-#{$key} {
		padding-right: #{$value} !important;
		padding-left: #{$value} !important;
	}

	.py-#{$key} {
		padding-top: #{$value} !important;
		padding-bottom: #{$value} !important;
	}

	.pt-#{$key} {
		padding-top: #{$value} !important;
	}

	.pe-#{$key} {
		padding-right: #{$value} !important;
	}

	.pb-#{$key} {
		padding-bottom: #{$value} !important;
	}

	.ps-#{$key} {
		padding-left: #{$value} !important;
	}

	.top-#{$key} {
		top: #{$value} !important;
	}

	.bottom-#{$key} {
		bottom: #{$value} !important;
	}

	.right-#{$key} {
		right: #{$value} !important;
	}

	.left-#{$key} {
		left: #{$value} !important;
	}
}

@each $key, $value in $spacings {
	.col-gap-#{$key} {
		column-gap: #{$value};
	}

	.row-gap-#{$key} {
		row-gap: #{$value};
	}

	.gap-#{$key} {
		gap: #{$value};
	}

	.gap-m-#{$key} > * {
		margin-right: -#{$value};	
		:last-child {
			margin-right: 0;
		}	
	}
}
